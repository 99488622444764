import React from "react";

import { useState } from "react";

export const RootContext = React.createContext()

function RootProvider({ children }){
    const [user, setUser] = useState(null);
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const context = {
        user: user,
        setUser: setUser,
        isUserAdmin: isUserAdmin,
        setIsUserAdmin: setIsUserAdmin,
    }

    return (<RootContext.Provider value={context}>
        {children}
    </RootContext.Provider>)
} 

export default RootProvider;