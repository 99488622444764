import styles from "./Button.module.css";

export const Button = ({ children, onClick, className }) => {
   
    const styleList = {
        DEFAULT: "default",
        BTN_CV: "btnCv",
        BTN_CHANGEPASSWORD: "btnChangePassword",
        BTN_JOIN_MODAL: "btnJoinModal",
        BTN_JOIN_BIG: "btnJoinBig",
        BTN_RULES_BIG: "btnRulesBig",
        BTN_SIGNIN:"btnSignIn",
        BTN_FORGOT:"btnForgotPass",
        BTN_LOGIN:"btnLogin",
        BTN_INTERVIURI:"btnInterviuri"
      };
      
    const applyStyle = (className) => {
      switch (className) {
        case styleList.BTN_CHANGEPASSWORD:
          return `${styleList.BTN_CHANGEPASSWORD}`;
        case styleList.BTN_CV:
          return `${styleList.BTN_CV}`;
        case styleList.BTN_SIGNIN:
          return `${styleList.BTN_SIGNIN}`;
        case styleList.BTN_LOGIN:
          return `${styleList.BTN_LOGIN}`;
        case styleList.BTN_INTERVIURI:
          return `${styleList.BTN_INTERVIURI}`;
        case styleList.BTN_FORGOT:
          return `${styleList.BTN_FORGOT}`;
        default:
          return `${styleList.DEFAULT}`;
      }
    };
    return (
      <button className={`${styles[applyStyle(className)]}`} onClick={onClick}>
        {children}
      </button>
    );
  };
  