import React, { useState, useContext } from "react";
import style from "./FormularSignIn.module.css";
import { Button } from "../Button/Button";
import { FaArrowLeft, FaSignInAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import { RootContext } from "../../context/RootProvider";

import { Link } from "react-router-dom";

import { logInUser } from "../../utils/apiCalls";

function FormularSignIn() {
  const [formData, setFormData] = useState({email: "", password: ""})
  const { email, password } = formData

  const userContext = useContext(RootContext)
  const { setUser, setIsUserAdmin } = userContext

  const navigate = useNavigate()

  function onInputChange(field, value){
    setFormData((prevFormData) => {
      return {...prevFormData, [field]: value}
    })
  }

  async function onSubmit(event){
    event.preventDefault()

    const userData = {
      email,
      password
    }

    try{
      const user = await logInUser(userData)

      localStorage.setItem("resetToken", user.resetToken)
  
      setUser(user)
      setIsUserAdmin(user.isAdmin)
      navigate("/")
    }catch(error){
      alert("Credentiale invalide")
    }
  }

  return (
    <div className={style.content}>
      <form action="">
        <div className={style.row}>
          <label className={style.label} htmlFor="">
            Email
          </label>
          <input value={email} onChange={(event) => {onInputChange("email", event.currentTarget.value)}} className={style.label} type="text" />
        </div>
        <div className={style.row}>
          <label className={style.label} htmlFor="">
            Parolă
          </label>
          <input value={password} onChange={(event) => {onInputChange("password", event.currentTarget.value)}} className={style.label} type="password" />
        </div>

        <div className={style.buttons}>
          {/* <Button className="btnForgotPass">

            <Link to="/forgotPassword" style={{textDecoration: "none", color: "white"}}>
              <div className={style.align}>
                Am uitat Parola
              </div>
              </Link>

          </Button> */}
          <div class="btnControls">
            <Button className="btnSignIn">

              <Link to="/" style={{textDecoration: "none", color: "white"}}>
              <div className={style.align}>
                Înapoi <FaArrowLeft fontSize={20} />
              </div>
              </Link>

            </Button>
            <Button onClick={ onSubmit} className="btnSignIn">
              <div className={style.align}>
                Login <FaSignInAlt fontSize={20} />
              </div>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FormularSignIn;
