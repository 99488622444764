import React, { useState } from 'react'

import styles from "./AnswerMultipleChoice.module.css"

import { Checkbox } from 'primereact/checkbox';
        
function AnswerMultipleChoice({ answerIndex, answer, handleAnswer }) {
    const [answerChecked, setAnswerChecked] = useState(false)

    function onAnswerPicked(answerIndex, isAnswerRight, isAnswerSelected){
        const isAnswerChecked = !isAnswerSelected
        
        handleAnswer(answerIndex, isAnswerRight, isAnswerChecked)
        setAnswerChecked(!answerChecked)
    }

  return (
    <div onClick={() =>{ onAnswerPicked(answerIndex, answer.esteCorect, answerChecked); console.log(answer.text)}} className={styles.answerContainer}>
        <Checkbox checked={answerChecked} className={styles.checkbox} />
        <span className={styles.answerText}>{answer.text}</span>
    </div>
  )
}

export default AnswerMultipleChoice