import React, { useState } from 'react'

import styles from "./Table.module.css"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import { feedbackFilterOptions } from '../../../const';

import { rowsToDisplay, API_URL } from '../../../const';

import axios from 'axios';
import { useEffect } from 'react';
import { getUsers } from '../../../utils/apiCalls';
import { useLocation } from 'react-router-dom';

const columns = [
  { field: "id", header: "User ID", sortable: false, dataType: "text" },
  { field: "firstName", header: "First Name", sortable: false, dataType: "text" },
  { field: "lastName", header: "Last Name", sortable: false, dataType: "text" },
  { field: "email", header: "Email", sortable: false, dataType: "text" },
  { field: "phone", header: "Phone", sortable: false, dataType: "text" },
  { field: "facebook", header: "Facebook", sortable: false, dataType: "text" },
  { field: "linkedin", header: "Linkedin", sortable: false, dataType: "text" },
  { field: "university", header: "University", sortable: false, dataType: "text" },
  { field: "year", header: "Year", sortable: false, dataType: "text" },
  { field: "echivalarePractica", header: "Echivalare", sortable: false, dataType: "boolean" },
];

function Table() {

  const location = useLocation();
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(location.search === '?view=feedback' ? 'feedback' : null);
  }, [location.search]);

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [rows, setRows] = useState(6)
    const [firstRowIndex, setFirstRowIndex] = useState(0)
    const [filters, setFilters] = useState(null);

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const getData = async () => {
      try {
        let tableData = null;
        if(type === 'feedback') {
          const feedbackResponse = await axios.get(`${API_URL}/api/feedback`);
          tableData = feedbackResponse.data;
        } else {
          const { users } = await getUsers();
          tableData = users;
        }
        setData(tableData);
        setIsLoading(false) 

        initFilters()
      } catch (error) {
        setData([])
        setIsLoading(false) 

        initFilters()
      }
    }

    useEffect(() => {
        getData();
    }, [type])

    const onPage = (event) => {
      const startIndex = event.first
      setFirstRowIndex(startIndex)
    }

    const dynamicColumns = type === 'feedback' ? 
      feedbackFilterOptions.map((filterField) => {
        return <Column filter key={filterField.column} field={filterField.column} header={filterField.value} filterField={filterField.column} />
      })
      :
      columns.map((column) => {
        return <Column key={column.field} 
        field={column.field} 
        header={column.header} 
        sortable={column.sortable}
        filter 
        dataType={column.dataType} />
      })

    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
  }

    const initFilters = () => {      
      setFilters(type === 'feedback' ? {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'emailIntervievat': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'numeIntervievat': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'numeObservator': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'numeIntervievator': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'notaExperienta': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        'notaSoftSkills': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        'notaHardSkills': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        'notaTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        'optiunea1': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'optiunea2': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'optiunea3': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'observatii': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
      } : {
        'global': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'firstName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'lastName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'telefon': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        'facebook': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'linkedin': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'universitate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'facultate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
        'anul': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
        'vreiEchivalare': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]},
      });
      setGlobalFilterValue('');
  }

  const clearFilter = () => {
    initFilters();
}

  const renderHeader = () => {
    return (
        <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
    )
}

const header = renderHeader();

  return (
    <div className={styles.tableContainer}>

        <div className={styles.dropdownContainer}>
        <p>Select the number of rows to display</p>

        <Dropdown optionLabel='rows' 
                  value={rows} 
                  optionValue="rows" 
                  options={rowsToDisplay} 
                  onChange={(event) => {
                                        setRows(event.value)
                                        setFirstRowIndex(0)
                                        }} 
                  />
        </div>

        <DataTable 
        value={data}  
        filters={filters}
        globalFilterFields={type === 'feedback' ? ['id', 'emailIntervievat', 'numeIntervievat', 'numeObservator', 'numeIntervievator', 'notaExperienta', 'notaSoftSkills', 'notaHardSkills', 'notaLingvistic', 'notaTotal', 'optiunea1', 'optiunea2', 'optiunea3', 'observatii'] : ['id', 'firstName', 'lastName', 'email', 'telefon', 'facebook', 'linkedin', 'universitate', 'facultate', 'anul', 'vreiEchivalare']} 
        header={header}
        paginator
        onPage={onPage} 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        first={firstRowIndex}
        responsiveLayout="scroll" 
        rows={rows} 
        loading={isLoading}       
        emptyMessage="No users found" 
        showGridlines>
          {dynamicColumns}
        </DataTable>

    </div>
  )
}

export default Table