import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../Components/FormularInterviu/DataTable/DataTable";
import useSelectedInterval from "../../hooks/useSelectedInterval";
import style from "./Interviu.module.css";
import placeholderImage from "./../../assets/images/placeholder.svg";

import { RootContext } from "../../context/RootProvider";
import { useContext } from "react";



function Interviu() {
  //TODO: Get the user ID from saved;
  const { user } = useContext(RootContext)

  const [forceReset, setForceReset] = useState(false);

  const userInterval = useSelectedInterval(user.id);
  
  switch (userInterval.hasIntervalSelected) {
    case 1:
      return (
        <div className={style.content}>
          <img src={placeholderImage} alt="" />
          <div className={style.hasIntervalSelected_wrapper}>
            <p>Ai ales data de:</p>
            <bold>{transformDate(userInterval.data)}</bold>
            <p>intre orele</p>
            <bold>{userInterval.interval}</bold>
          </div>
        </div>
      );

    case 2:
      return <DataTable userId={user.id} reset={setForceReset} />;

    default:
      return <div>Momentan aplicatia nu functioneaza</div>;
  }
}
function transformDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(2);
  return `${day}.${month}.20${year}`;
}
export default Interviu;
