import React from 'react'

import styles from "./AdminModal.module.css"

function AdminModal({ showMenu, setShowMenu }) {
  function handleClick(){
    setShowMenu(false)
  }

  return (
    <div onClick={handleClick} className={`${styles.modal} ${!showMenu ? styles.hideModal : ''}`}>

    </div>
  )
}

export default AdminModal