import React, { useEffect, useRef, useState } from 'react'

import styles from "./ConfirmTest.module.css"

import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';

import { TestContext } from '../../../context/TestProvider'
import { useContext } from 'react'

import { postTest } from '../../../utils/apiCalls';

import LogoTTJ from "../../../assets/images/ttj_logo.png"

function ConfirmTest({ setShowPopup }) {
  const { testData, dispatchTest } = useContext(TestContext)

  const [totalPoints, setTotalPoints] = useState()

  const statusRef = useRef(null)

  const { partner, name, multipleChoice, noQuestions, questions } = testData

  let typeOfTest = multipleChoice ? "Multiple choice" : "Open answer"

  useEffect(() => {
    let totalP = 0;
    for(const question of questions){
      totalP += parseInt(question['punctaj'])
    }

    setTotalPoints(totalP)
  }, [])

  function previousQuestion(){
    const payload = testData.currentStep - 1

    dispatchTest({type: "currentStep", payload})
}

console.log(testData)

async function submitTest(){
  const sentData = {
    partener: partner,
    nume: name,
    nrIntrebari: noQuestions,
    intrebari: questions,
    esteGrila: multipleChoice
  }

  if(totalPoints !== 100 && multipleChoice){
    statusRef.current.show({ severity: "error", summary: "Total points", detail: "Total number of points must be 100" })
  }else{
    try{
      await postTest(sentData)
  
      statusRef.current.show({ severity: "success", summary: "Created", detail: `${partner} test was successfully created.` })
      dispatchTest({ type: "reset" })
      setShowPopup(false)
    }catch(error){
      statusRef.current.show({ severity: "error", summary: "Error", detail: `Server error. ${partner} test was not created` })
    }
  }
}

  return (
    <div>
      <Toast ref={statusRef} />
      <h1 className={styles.header}>{name} - {partner}</h1>

      <p className={styles.info}>Test type: {typeOfTest}</p>
      <p className={styles.info}>Number of questions: {noQuestions}</p>
      {multipleChoice && <p className={styles.info}>Total points: {totalPoints}</p>}

      <div className={`${!multipleChoice ? styles.imageContainer : styles.updatedImageContainer}`}>
        <img className={styles.image} src={LogoTTJ} alt="logo-ttj"  />
      </div>

      <div className={styles.buttonContainer}>
        <Button onClick={previousQuestion} label="Previous" />
        <Button onClick={submitTest} label="Confirm test" />
      </div>
    </div>
  )
}

export default ConfirmTest