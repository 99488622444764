import axios from "axios";
import { API_URL } from "../const";

export default async function PostInterval(userId, interviuId) {
  //TODO: Modify the url
  const res = await axios
    .put(`${API_URL}/api/user/attendInterview/` + userId, {
      interviuId: interviuId,
    })
    .then((res) => {
      console.log("Succes: ", res.data);
      return { message: res.data, status: res.status };
    }).catch((err) => {
      console.log("Eroare: ", { message: err.response.data, status: err.response.status });
      return { message: err.response.data, status: err.response.status };
    })
  return res;
}
