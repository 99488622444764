import React from 'react'

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

import style from "./Wrapper.module.css"

import { Outlet } from 'react-router-dom'

function Wrapper() {
  return (
    <div className={style.App}>
        <Navbar />
            <Outlet />
        <Footer />
    </div>
  )
}

export default Wrapper