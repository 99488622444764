import React, { useEffect, useRef, useState } from 'react'

import styles from "./TestMultipleChoice.module.css"

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import AnswerMultipleChoice from './AnswerMultipleChoice';

import { UserTestContext } from '../../context/UserTestProvider';
import { useContext } from 'react';
import { postUserTest } from '../../utils/apiCalls';

import { useParams } from 'react-router-dom';

function TestMultipleChoice({ selectedTest, currentQuestion, nextQuestion }) {
    const question = selectedTest.intrebari[currentQuestion - 1]
    const lastQuestion = selectedTest.nrIntrebari

    const [questionRight, setQuestionRight] = useState(false)
    const [answerSelected, setAnswerSelected] = useState([])

    const { dispatchUserTest, userTestData } = useContext(UserTestContext)

    const selectAnswerRef = useRef()

    const { userID, testID } = useParams()

    useEffect(() => {
        dispatchUserTest({ type: "loadTest", payload: { testId: testID, userId: userID } })
    }, [])

    useEffect(() => {
        const initAnswersSelected = []
        for (let i = 0; i < question.raspunsuri.length; i++) {
            initAnswersSelected.push(false)
        }

        setQuestionRight(false)
        setAnswerSelected(initAnswersSelected)
    }, [currentQuestion])

    function handleAnswers(answerIndex, isAnswerRight, isAnswerSelected) {
        const newAnswerSelected = [...answerSelected]

        if (isAnswerSelected) {
            newAnswerSelected[answerIndex] = true
        } else {
            newAnswerSelected[answerIndex] = false
        }

        setAnswerSelected(newAnswerSelected)

        if (isAnswerRight === 0 && newAnswerSelected[answerIndex] === true) {
            setQuestionRight(false)
        } else {
            let answersRight = true

            for (let i = 0; i < question.raspunsuri.length; i++) {
                if (question.raspunsuri[i].esteCorect === 0 && newAnswerSelected[i] === true || question.raspunsuri[i].esteCorect === 1 && newAnswerSelected[i] == false) {
                    answersRight = false
                    break
                }
            }

            setQuestionRight(answersRight)
        }
    }

    async function handleSendResponse() {
        if (answerSelected.includes(true)) {
            if (questionRight) {
                dispatchUserTest({ type: "answer", payload: { punctaj: question.punctaj } })
            } else {
                dispatchUserTest({ type: "answer", payload: { punctaj: 0 } })
            }

            if (currentQuestion === lastQuestion) {
                const dataSent = Object.assign(userTestData, {
                    punctajTotal: questionRight ? userTestData.punctajTotal + question.punctaj : userTestData.punctajTotal,
                    nrIntrebariCorecte: questionRight ? userTestData.nrIntrebariCorecte + 1 : userTestData.nrIntrebariCorecte
                })
                await postUserTest(dataSent)
                dispatchUserTest({ type: "reset" })

            }

            nextQuestion(currentQuestion + 1)
        } else {
            selectAnswerRef.current.show({ severity: "warn", summary: "Raspuns obligatoriu", detail: "Este obligatoriu sa selectezi un raspuns" })
        }
    }

    console.log(question);

    return (
        <div className={styles.testMultipleChoiceContainer}>
            <div>
                <Toast ref={selectAnswerRef} />
                <p className={styles.questionText}>{question.text}</p>

                {question.raspunsuri.map((raspuns, index) => {
                    return <AnswerMultipleChoice key={raspuns.id} answerIndex={index} answer={raspuns} handleAnswer={handleAnswers} />
                })}

                <div className={styles.buttonContainer}>
                    <Button label={currentQuestion < lastQuestion ? "Next" : "Confirm"} onClick={handleSendResponse} className={styles.mainButton} />
                </div>
            </div>
        </div>
    )
}

export default TestMultipleChoice