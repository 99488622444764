import React, { useRef } from "react";
import userImg from "../../assets/images/userTest.jpeg";
import { FaDochub, FaLock } from "react-icons/fa";
import style from "./ContentProfile.module.css";
import { Button } from "../Button/Button";
import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../context/RootProvider";

import { Link } from "react-router-dom";

import { changeCV } from "../../utils/apiCalls";

function ContentProfile() {
  const rootContext = useContext(RootContext);
  const [user, setUser] = useState(null);
  const [CV, setCV] = useState(null);

  const timeoutIDRef = useRef();

  const CVChangedMessage = CV
    ? `CV-ul a fost schimbat cu succes (${CV.name})`
    : "CV-ul trebuie sa existe. (CV-ul nu a fost schimbat)";

  useEffect(() => {
    setUser(rootContext.user);
  }, []);

  async function onChangeCV(CVData) {
    clearTimeout(timeoutIDRef.current);

    const userData = {
      email: user?.email,
      nume: user?.nume,
      prenume: user?.prenume,
      CV: CVData,
    };


    if (CVData) {
      const rezultat = await changeCV(userData);
    }

    setCV(CVData);

    timeoutIDRef.current = setTimeout(() => {
      setCV(null);
    }, 7500);
  }

  return (
    <div className={style.content}>
      <div>
        <img className={style.profilePicture} src={userImg} alt="user-image" />
        <div className={style.userInfo}>
          <h2>
            <span>{user?.nume}</span> <span>{user?.prenume}</span>
          </h2>
          <p>
            <span>Universitate: </span> <span>{user?.universitate}</span>
          </p>
          <p>
            <span>Mail: </span> <span>{user?.email}</span>
          </p>
          <p>
            <span>Telefon: </span> <span>{user?.telefon}</span>
          </p>
          <div className={style.buttonZone}>
            {/* <Link to={`changePassword/${user?.resetToken}`}>
              <Button className="btnChangePassword">
                <div className={style.align}>
                  <FaLock fontSize={20} />
                  <br />
                  <p className={style.textButtons}>Schimbă Parola</p>
                </div>
              </Button>
            </Link> */}

	  {/* {<div className={style.inputCV}>
          <input onChange={(e) => onChangeCV(e.target.files[0])} name="CV" id="CV" type="file" accept="application/pdf" />
          <label htmlFor="CV" className={`${style.align} ${style.btnCv}`}>
            <FaDochub
            fontSize={20}></FaDochub>
            <br />
            <p className={style.textButtons}>Schimbă CV-ul</p>
            </label>
          </div>
	  } */}

          </div>
    {/* <h3 className={style.important}>Testul tehnic durează maxim 30 de minute, poate fi dat o singură dată și trebuie dat până pe 17.03.2024.</h3> */}


          <p
            className={`${style.confirmCVMessage} ${
              CV !== null ? style.confirmCVActive : style.confirmCVChanged
            }`}>
            {CVChangedMessage}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContentProfile;
