import React, { useRef, useEffect } from 'react'

import { useForm, useFieldArray, Controller, FormProvider } from "react-hook-form"

import styles from "./QuestionData.module.css"

import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';

import { TestContext } from '../../../context/TestProvider'
import { useContext } from 'react'

import { nrRaspunsuri, Raspuns } from '../../../const'
import AnswerTest from './AnswerTest'

function QuestionData({ data }) {
  const { testData, dispatchTest } = useContext(TestContext)

  const answerErrorRef = useRef(null)

  const methods = useForm({defaultValues: { 
    nrIntrebare: data.nrIntrebare,
    text: "",
    nrRaspunsuri: 0,
    punctaj: "",
    raspunsuri: []
  }})

  const { register, getValues, handleSubmit, control, formState: {errors}, watch } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "raspunsuri"
  })

  const { currentStep, noQuestions } = testData

  const noAnswers = watch("nrRaspunsuri")
  const previousNumberOfAnswers = useRef(0)

  function handleAnwers(){
    let answersChange = noAnswers - previousNumberOfAnswers.current

    if(answersChange > 0){
      let newAnswer = new Raspuns("", false)

      for(let i = 1; i <= answersChange; i++){
        append(newAnswer)
      }
    }else if(answersChange < 0){
      for(let i = previousNumberOfAnswers.current - 1; i >= noAnswers; i--){
        remove(i)
      }
    }

    previousNumberOfAnswers.current = noAnswers
  }

  function previousQuestion(){
    const payload = testData.currentStep - 1

    dispatchTest({type: "currentStep", payload})
}

function nextQuestion(){
    const payload = testData.currentStep + 1

    dispatchTest({type: "currentStep", payload})
}

function onSubmit(){
  const questions = testData.questions
  const selectedQuestion = questions[currentStep - 1]

  const currentQuestionValues = getValues()

  if(JSON.stringify(selectedQuestion) !==  JSON.stringify(currentQuestionValues)){
      dispatchTest({type: "questions", payload: currentQuestionValues})
    }

    const answersToQuestion = currentQuestionValues['raspunsuri']

    let answersValid = true
    let checksValid = false
    let noAnswersChecked = 0
  
    for(const answer of answersToQuestion){
      if(answer['text'] === ""){
        answersValid = false;
        answerErrorRef.current.show({ severity: 'error', summary: 'Answer text', detail: 'Answer text is required' })
        break;
      }
  
      if(answer['esteCorect'] === true){
        noAnswersChecked += 1
      }
    }

    if(noAnswersChecked === 0 && answersValid){
      answerErrorRef.current.show({ severity: 'error', summary: 'Correct answer', detail: 'A correct answer is required to every question' })
    }else if(noAnswersChecked === noAnswers && answersValid){
      answerErrorRef.current.show({ severity: 'error', summary: 'Correct answer', detail: 'Not all answers can be correct' })
    }else{
      checksValid = true
    }

    if(answersValid && checksValid){
      nextQuestion();
    }
}

useEffect(() => {
  handleAnwers()
}, [noAnswers])

  if(currentStep === data.nrIntrebare){
    return <div>
    <h1 className={styles.header}>{testData.partner}'s {testData.name} - Question {data.nrIntrebare}</h1>

    <Toast ref={answerErrorRef} />

<FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className={styles.questionContainer}>
      <label htmlFor="question">Question: </label>

      <div>
      <textarea rows={4} id="question" {...register("text", { required: "Question text is required" })} className={styles.question} />
      <p className={styles.error}>{errors.text?.message}</p>
      </div>
    </div>

    <div className={`${styles.questionContainer} ${styles.answersPoints}`}>
      <div>
        <Controller
        name="nrRaspunsuri"
        control={control}  
        rules={{ min: { value: 3, message: "Answers required"} }} 
        render={({ field }) => (
           <Dropdown 
          value={field.value}
          options={nrRaspunsuri} 
          placeholder="Answers" 
          name="nrRaspunsuri"
          control={control}
          onChange={(event) => field.onChange(event.value)}
          className={styles.dropdown}
          />
        )}/>
      <p className={styles.error}>{errors.nrRaspunsuri?.message}</p>
      </div>

      <div>
      <input {...register("punctaj", { required: "Points required", min: {value: 1, message: "Points must be equal or greater than 1"}, max: { value: 50, message: "Points must be equal or less than 50"} })} type="number" placeholder='Points' className={styles.points} />
      <p className={styles.error}>{errors.punctaj?.message}</p>
      </div>
    </div>

    <div className={styles.answersContainer}>
      {fields.map((item, index) => {
        return <AnswerTest key={item.id} data={item} index={index} />
      })}
    </div>

    <div className={styles.buttonContainer}>
      <Button onClick={previousQuestion} label="Previous" className={`${currentStep === 1 ? styles.buttonHide : ""}`} />
      <Button  type="submit" label= "Next" className={`${currentStep > noQuestions ? styles.buttonHide : ""}`} />
    </div>  
    </form>
    </FormProvider>
  </div>
  }
}

export default QuestionData