import React, { useState } from 'react'

import TestProvider from '../../../context/TestProvider';

import CreateTest from '../../../Components/Admin/LoadTests/CreateTest';
import TestContainer from '../../../Components/Admin/LoadTests/TestContainer';
import TestModal from '../../../Components/Admin/LoadTests/TestModal';

function LoadTests() {
    const [showPopup, setShowPopup] = useState(false);

  if(!showPopup){
    return <TestProvider> <CreateTest setShowPopup={setShowPopup}/> </TestProvider>
  }else{
    return <TestProvider>
    <TestModal />
    <TestContainer setShowPopup={setShowPopup} />
    </TestProvider>
  }
}

export default LoadTests