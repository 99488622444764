import React, { useRef } from 'react'

import styles from "./TestContainer.module.css"

import { OverlayPanel } from 'primereact/overlaypanel'

import { AiOutlineClose } from "react-icons/ai"
import { Button } from 'primereact/button'

import { TestContext } from '../../../context/TestProvider';
import { useContext } from 'react';

import QuestionData from './QuestionData'
import OpenQuestion from './OpenQuestion'
import ConfirmTest from './ConfirmTest'

function TestContainer({ setShowPopup }) {
    const { testData, dispatchTest } = useContext(TestContext)

    const { multipleChoice, questions, currentStep } = testData

    const overlayRef = useRef()

    function onClose(){
        setShowPopup(false)
        dispatchTest({type: "reset"})
    }

  return (
    <div className={styles.testContainer}>
        <div className={styles.innerContainer}>
        <div onClick={(event) => overlayRef.current.show(event)} onMouseOver={({target})=>target.style.color="#280F43"} onMouseOut={({target})=>target.style.color="white"} className={styles.closeIcon}><AiOutlineClose size={24} color="white" /></div>

        <OverlayPanel ref={overlayRef}>
            <div>Are you sure you want to stop the process?</div>

            <div className={styles.confirmationButtonsContainer}>
                <Button icon="pi pi-check" aria-label="Confirm" onClick={onClose} />
                <Button icon="pi pi-times" aria-label="Cancel" className="p-button-danger" onClick={(event) => overlayRef.current.hide(event)} />
            </div>
        </OverlayPanel>

        {questions.map((question, index) => {
            if(multipleChoice){
                return <QuestionData key={index} data={question} />
            }else{
                return <OpenQuestion key={index} data={question} />
            }
        })}

        {currentStep > questions.length && <ConfirmTest setShowPopup={setShowPopup} />}
        </div>
    </div>
  )
}

export default TestContainer