import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../const";

export default function useAvailableIntervals() {
  //TODO: Modify the url

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data == null)
      axios.get(`${API_URL}/api/interviu/getAllAvailableDates`).then((res) => {
        setData(res.data);
      });
  }, []);

  return data?.length > 0 ? data : null;
}
