import React from 'react'

import styles from "./OpenQuestion.module.css"

import { Button } from 'primereact/button'

import { useForm} from "react-hook-form"

import { TestContext } from '../../../context/TestProvider'
import { useContext } from 'react'

function OpenQuestion({ data }) {
    const { testData, dispatchTest } = useContext(TestContext)

    const { register, getValues, handleSubmit, formState: {errors} } = useForm({
        defaultValues: {
            nrIntrebare: data.nrIntrebare, 
            text: ""
        }
    })

    function previousQuestion(){
        const payload = testData.currentStep - 1
    
        dispatchTest({type: "currentStep", payload})
    }
    
    function nextQuestion(){
        const payload = testData.currentStep + 1
    
        dispatchTest({type: "currentStep", payload})
    }

    function onSubmit(){
        const questions = testData.questions
        const selectedQuestion = questions[testData.currentStep - 1]
      
        const currentQuestionValues = getValues()
      
        if(JSON.stringify(selectedQuestion) !==  JSON.stringify(currentQuestionValues)){
            dispatchTest({type: "questions", payload: currentQuestionValues})
          }

          nextQuestion()
    }

    if(testData.currentStep === data.nrIntrebare){
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                 <h1 className={styles.header}>{testData.partner}'s {testData.name} - Question {data.nrIntrebare}</h1>

                 <div className={styles.questionContainer}>
      <label htmlFor="question">Question: </label>

      <div>
      <textarea rows={20} id="question" {...register("text", { required: "Question text is required" })} className={styles.question} />
      <p className={styles.error}>{errors.text?.message}</p>
      </div>
    </div> 

                <div className={styles.buttonContainer}>
                    <Button onClick={previousQuestion} label="Previous" className={`${testData.currentStep === 1 ? styles.buttonHide : ""}`} />
                    <Button  type="submit" label= "Next" className={`${testData.currentStep > testData.noQuestions ? styles.buttonHide : ""}`} />
                </div>    
            </form>
          )
    }
}

export default OpenQuestion