import React, {useEffect, useState} from "react";

import styles from "./InterviewTable.module.css";

import {Dropdown} from "primereact/dropdown";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {rowsToDisplay} from "../../../const";

import date from "date-and-time";
import useAllSelectedInterviews from "../../../hooks/useAllIntervals";

const DUMMY_VALUES = [
  {
    nume: "Gigel1",
    prenume: "Robert1",
    email: "fakeEmail1@gmail.com",
    data: new Date("March 1, 2023 03:24:00"),
  },
  {
    nume: "Gigel2",
    prenume: "Robert2",
    email: "fakeEmail2@gmail.com",
    data: new Date("March 2, 2023 05:24:00"),
  },
  {
    nume: "Gigel3",
    prenume: "Robert3",
    email: "fakeEmail3@gmail.com",
    data: new Date("March 3, 2023 20:24:00"),
  },
  {
    nume: "Gigel4",
    prenume: "Robert4",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
  {
    nume: "Gigel5",
    prenume: "Robert5",
    email: "fakeEmail5@gmail.com",
    data: new Date("March 10, 2023 10:24:00"),
  },
  {
    nume: "Gigel6",
    prenume: "Robert6",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
  {
    nume: "Gigel7",
    prenume: "Robert7",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
  {
    nume: "Gigel8",
    prenume: "Robert8",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
  {
    nume: "Gigel9",
    prenume: "Robert9",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
  {
    nume: "Gigel10",
    prenume: "Robert10",
    email: "fakeEmail4@gmail.com",
    data: new Date("March 4, 2023 07:24:00"),
  },
];

const loadInterviews = async (interviewsData) => {
  if (interviewsData === null || interviewsData === undefined)
    return null;

  let initialInterviews = [...interviewsData];

  return initialInterviews.map((interview) => {
    const now = new Date();
    if (interview.data > now) {
      let day = date.format(interview.data, "DD/MM/YYYY");
      let hour = interview.ora;

      return {...interview, data: day, ora: hour};
    }
  });
}

function InterviewTable() {
  const [interviews, setInterviews] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(6);
  const [firstRowIndex, setFirstRowIndex] = useState(0);

  const rowData = useAllSelectedInterviews();
  const currentData = rowData?.map((oldElement) => {
    return {
      nume: oldElement.users[0].firstName,
      prenume: oldElement.users[0].lastName,
      email: oldElement.users[0].email,
      data: new Date(oldElement.data),
      ora: oldElement.interval,
    };
  });

  const loadData = async () => {
    const interviewsData = await loadInterviews(currentData);
    let currentInterviews = [];

    if (interviewsData) {
      for (const element of interviewsData) {
        if (element) {
          currentInterviews.push(element);
        }
      }
      setInterviews(currentInterviews);
      setIsLoading(false);
    }

  };

  useEffect(() => {
    loadData();
  }, [rowData]);


  const onPage = (event) => {
    const startIndex = event.first;
    setFirstRowIndex(startIndex);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.dropdownContainer}>
        <p>Select the number of rows to display</p>

        <Dropdown
          optionLabel="rows"
          value={rows}
          optionValue="rows"
          options={rowsToDisplay}
          onChange={(event) => {
            setRows(event.value);
            setFirstRowIndex(0);
          }}
          placeholder="Rows to display"
        />
      </div>

      <DataTable
        value={interviews}
        loading={isLoading}
        paginator
        rows={rows}
        first={firstRowIndex}
        onPage={onPage}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} interviews"
      >
        <Column field="nume" filter header="Nume"></Column>
        <Column field="prenume" filter header="Prenume"></Column>
        <Column field="email" filter header="Email"></Column>
        <Column field="data" header="Data" sortable dataType="date"></Column>
        <Column field="ora" header="Ora" sortable></Column>
      </DataTable>
    </div>
  );
}

export default InterviewTable;
