import React from "react";
import style from "./LandingPage.module.css";
import Form from '../../Components/FormularSignIn/FormularSignIn'
import { Button } from "../../Components/Button/Button";
import Logo from "../../assets/images/Siglă-Wide-Fundal-Galben.png";

import { Link } from "react-router-dom";

function LandingPage() {
  return (
    <div className={style.contentPage}>
      <img className={style.logo} src={Logo} alt="TTJ Logo" />
      <div className={style.bottonZone}>
        {/* { <Link to="/register">
          <Button className="btnSignIn">Sign up</Button>
        </Link> } */}

        <Link to="/login">
          <Button className="btnLogin">Login</Button>
        </Link>
      </div>
    </div>
  );
}

export default LandingPage;
