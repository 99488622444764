import React, { useEffect, useReducer, useState } from 'react'

import { getTests } from '../utils/apiCalls'

import { Intrebare } from '../const'

export const TestContext = React.createContext()

const initialState = {
  partner: "",
  name: "",
  multipleChoice: null,
  noQuestions: 1,
  questions: [],
  currentStep: 1,
}

function handleTestData(state, action){
  let newState = {...state}

  switch(action.type){
    case "partner":
      const partner = action.payload
      newState['partner'] = partner

      return newState

    case "name":
      const name = action.payload
      newState['name'] = name

      return newState

    case "testType":
      const isMultipleChoice = action.payload
      newState['multipleChoice'] = isMultipleChoice

      return newState

    case "noQuestions":
      const noQuestion = action.payload
      newState['noQuestions'] = noQuestion

      let questionINIT = []
      for(let i = 1; i <= noQuestion; i++){
       const question = new Intrebare(i)

       questionINIT.push(question)
      }

      newState['questions'] = [...questionINIT]

      return newState

    case "questions":
      const currentQuestion = newState.currentStep
      const updatedQuestion = action.payload
      
      let questions = [...newState['questions']]
      questions[currentQuestion - 1] = updatedQuestion

      newState['questions'] = questions

      return newState

    case "currentStep":
      const currentStep = action.payload
      newState['currentStep'] = currentStep

      return newState

    case "reset":
      newState = {...initialState}

      return newState

    default:
      return newState
  }
}

function TestProvider({ children }) {
  const [testData, setTestData] = useReducer(handleTestData, initialState)
  const [tests, setTests] = useState([])

  useEffect(() => {
    const loadTests = async() => {
      const testsReceived = await getTests()
      setTests(testsReceived)
    }
  
    loadTests()
  }, [])

  const testDataValue = {
    testData,
    dispatchTest: setTestData,
    tests,
    setTests,
  }

  return (
    <TestContext.Provider value={testDataValue}>{children}</TestContext.Provider>
  )
}

export default TestProvider