import React from 'react'

import TestProvider from '../../context/TestProvider'

import LandingTests from "../../Components/TesteTehnice/LandingTests"

function TesteTehnice() {
  return(
    <TestProvider>
      <LandingTests />
    </TestProvider>
  )
}

export default TesteTehnice
