import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../const";

export default function useSelectedInterval(userId) {
  //TODO: Modify the url

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data == null)
      axios
        .get(`${API_URL}/api/user/getDataInterviu/` + userId)
        .then((res) => {
          console.log({res_data:res.data});
          setData(res.data);
        })
        .catch((err) => {
          console.log({ err: err });
        });
  });

  if (data != null) {
    return {
      hasIntervalSelected: 1,
      data: data.interviuri.data,
      interval: data.interviuri.timp,
    };
  } else {
    return { hasIntervalSelected: 2 };
  }
}
