import React, { useEffect, useRef, useState } from 'react'

import styles from "./TestMultipleChoice.module.css"
import stylesOpen from "./TestOpenAnswer.module.css"


import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import AnswerMultipleChoice from './AnswerMultipleChoice';

import { UserTestContext } from '../../context/UserTestProvider';
import { useContext } from 'react';
import { postUserTest } from '../../utils/apiCalls';
import { InputTextarea } from 'primereact/inputtextarea';


import { useParams } from 'react-router-dom';
import { Intrebare } from '../../const';

import QuestionImg from './QuestionImg';
import axios from 'axios';

import {API_URL} from '../../const'



function TestBothQuestionTypes({ selectedTest, currentQuestion, nextQuestion }) {
    const question = selectedTest.intrebari[currentQuestion - 1]
    const lastQuestion = selectedTest.nrIntrebari

    const [questionRight, setQuestionRight] = useState(false)
    const [answerSelected, setAnswerSelected] = useState([])
    const [answer, setAnswer] = useState("")

    const { userOpenTest, dispatchUserTest, dispatchUserOpenTest, userTestData } = useContext(UserTestContext)

    const selectAnswerRef = useRef()
    const validAnswer = useRef()


    const { userID, testID } = useParams()

    useEffect(() => {

        dispatchUserTest({ type: "loadTest", payload: { testId: testID, userId: userID } });
        dispatchUserOpenTest({ type: "loadTest", payload: { testId: testID, userId: userID } });
    }, [])

    useEffect(() => {
        if (question.este_grila) {
            const initAnswersSelected = []
            for (let i = 0; i < question.raspunsuri.length; i++) {
                initAnswersSelected.push(false)
            }
            setAnswerSelected(initAnswersSelected)
        }

        setAnswer("");
        setQuestionRight(false);
    }, [currentQuestion])

    async function handleAnswer() {
        let answerContent = answer.trim()
        const gridAnswers = [];
        question?.raspunsuri?.forEach((raspuns, index) => {
            if (answerSelected[index] === true) {
                axios.post(`${API_URL}/api/raspunsUser/addRaspuns`, {
                    text: raspuns.text,
                    idraspuns: raspuns.id,
                    idintrebare: question.id,
                    userId: userID
                }).then((res) => {
                    console.log(res);
                })
            }
        });
        console.log(gridAnswers);

        if (answerContent.length > 0) {
            dispatchUserOpenTest({ type: "answer", payload: { intrebareId: question.id, text: answerContent } })
            nextQuestion(currentQuestion + 1)

            if (currentQuestion === lastQuestion) {
                let sentResponses = [...userOpenTest.raspunsuri];
                sentResponses.push({
                    intrebareId: question.id,
                    text: answerContent,
                });

                const dataSent = Object.assign(userOpenTest, {
                    raspunsuri: sentResponses
                })
                await postUserTest(dataSent)
                dispatchUserTest({ type: "reset" })

            }
        } else {
            validAnswer.current?.show({ severity: "warn", summary: "Raspuns obligatoriu", detail: "Este obligatoriu sa dai un raspuns" })
        }
    }

    function handleAnswers(answerIndex, isAnswerRight, isAnswerSelected) {
        const newAnswerSelected = [...answerSelected]

        if (isAnswerSelected) {
            newAnswerSelected[answerIndex] = true
        } else {
            newAnswerSelected[answerIndex] = false
        }

        setAnswerSelected(newAnswerSelected)

        if (isAnswerRight === 0 && newAnswerSelected[answerIndex] === true) {
            setQuestionRight(false)
        } else {
            let answersRight = true

            for (let i = 0; i < question.raspunsuri.length; i++) {
                if (question.raspunsuri[i].esteCorect === 0 && newAnswerSelected[i] === true || question.raspunsuri[i].esteCorect === 1 && newAnswerSelected[i] == false) {
                    answersRight = false
                    break
                }
            }

            setQuestionRight(answersRight)
        }
    }

    async function handleSendResponse() {
        if (answerSelected.includes(true)) {
            if (questionRight) {
                dispatchUserTest({ type: "answer", payload: { punctaj: question.punctaj } })
            } else {
                dispatchUserTest({ type: "answer", payload: { punctaj: 0 } })
            }

            if (currentQuestion === lastQuestion) {
                const dataSent = Object.assign(userTestData, {
                    punctajTotal: questionRight ? userTestData.punctajTotal + question.punctaj : userTestData.punctajTotal,
                    nrIntrebariCorecte: questionRight ? userTestData.nrIntrebariCorecte + 1 : userTestData.nrIntrebariCorecte
                })
                console.log(dataSent)
                await postUserTest(dataSent)
                dispatchUserTest({ type: "reset" })

            }

            nextQuestion(currentQuestion + 1)
        } else {
            selectAnswerRef.current.show({ severity: "warn", summary: "Raspuns obligatoriu", detail: "Este obligatoriu sa selectezi un raspuns" })
        }
    }

    async function trimiteRaspuns() {
        handleSendResponse();
        handleAnswer();
    }


    if (question.este_grila) {
        return (
            <div className={styles.testMultipleChoiceContainer}>
                <div>
                    <Toast ref={selectAnswerRef} />
                    <p className={styles.questionText}>{question.text}</p>
                    <QuestionImg  currentQuestion={currentQuestion} />

                    {question.raspunsuri.map((raspuns, index) => {
                        return <AnswerMultipleChoice key={raspuns.id} answerIndex={index} answer={raspuns} handleAnswer={handleAnswers} onChange={(event) => {
                            <InputTextarea value={answer} onChange={(event) => { setAnswer(event.target.value) }} />
                        }} />
                    })}

                    <div className={styles.buttonContainer}>
                        <Button label={currentQuestion < lastQuestion ? "Next" : "Confirm"} onClick={trimiteRaspuns} className={styles.mainButton} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={stylesOpen.testOpenAnswerContainer}>
                <div>
                    <Toast ref={validAnswer} />
                    <p className={stylesOpen.questionText}>{question.text}</p>
                    <QuestionImg currentQuestion={currentQuestion} />

                    <div className={stylesOpen.textareaContainer}>
                        <InputTextarea value={answer} onChange={(event) => { setAnswer(event.target.value) }} />
                    </div>

                    <div className={stylesOpen.buttonContainer}>
                        <Button label={currentQuestion < lastQuestion ? "Next" : "Confirm"} className={styles.mainButton} onClick={trimiteRaspuns} />
                    </div>
                </div>
            </div>
        )
    }

}

export default TestBothQuestionTypes