import React from 'react'

import styles from "./AnswerTest.module.css"

import { useFormContext, Controller } from 'react-hook-form'

import { Checkbox } from 'primereact/checkbox';

function AnswerTest({ data, index }) {
  const { control, formState: {errors} } = useFormContext()

  function checkAnswer(field, event){
    const answers = [...field.value]

    let updatedData = {...data}
    updatedData['esteCorect'] = event.checked
    data['esteCorect'] = event.checked

    delete updatedData.id

    answers[index] = updatedData

    field.onChange(answers)
  }

  function handleAnswerText(field, event){
    const answers = [...field.value]

    let updatedData = {...data}
    updatedData['text'] = event.target.value
    data['text'] = event.target.value

    delete updatedData.id

    answers[index] = updatedData

    field.onChange(answers)
  }

  return (
    <>
    <div className={styles.answerContainer}>
      <Controller 
      name="raspunsuri"
      control={control}
      render={({ field }) =>{
        return( 
      <Checkbox
      value={data['esteCorect']}
      name="raspunsuri"
      control={control} 
      onChange={(event) => checkAnswer(field, event)} 
      checked={data['esteCorect']} />
  )}}
      />
      
      <Controller 
      name="raspunsuri"
      control={control}
      rules={{ minLength: {
        value: 1,
        message: "Answer needs content"
      } }}
      render={({ field }) => {
        return ( 
        <textarea
         id={field.name}
         value={data['text']}
         name="raspunsuri" 
         control={control}
         onChange={(event) => handleAnswerText(field, event)}
         rows={4} 
         placeholder={`Type answer ${index + 1}...`}
         className={styles.answerContent} /> 
        )
      }}
      />
    </div>
    <p className={styles.error}>{}</p>
    </>
  )
}

export default AnswerTest