import React, { useEffect, useState } from "react";
import axios from "axios";
import {API_URL} from "../const";

export default function useAllSelectedInterviews() {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data == null)
      axios
        .get(`${API_URL}/api/interviu/getAllSelectedInterviews`)
        .then((res) => {
          setData(res.data);
        });
  }, []);

  if (data != null) {
    return data;
  }
}
