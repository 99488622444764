import axios from "axios";
import { API_URL } from "../const";

export async function registerUser(userData) {
  const payload = new FormData();
  payload.append("nume", userData.firstName);
  payload.append("prenume", userData.lastName);
  payload.append("telefon", userData.phone);
  payload.append("email", userData.email);
  payload.append("parola", userData.password);
  payload.append("facebook", userData.facebook);
  payload.append("universitate", userData.university);
  payload.append("linkedin", userData?.linkedin);
  payload.append("specializare", userData.specialization);
  payload.append("anUniversitar", userData.year);
  payload.append("practica", userData.echivalarePractica);
  payload.append("CV", userData?.CV);

  return axios(`${API_URL}/api/user/addUser`, {
    method: "post",
    withCredentials: true,
    data: payload
  });
}

export async function logInUser(userData) {
  const response = await axios(`${API_URL}/api/user/login`, {
    method: "post",
    withCredentials: true,

    data: {
      email: userData.email,
      password: userData.password
    }
  });
  console.log(response);
  const { token } = response.data;
  localStorage.setItem("token", token);

  return response.data.user;
}

export async function getUsers() {
  const response = await axios.get(`${API_URL}/api/user/getAllUsers`, {
    method: "get",
    withCredentials: true
  });

  return { users: response.data, numberOfUsers: response.data.length };
}

export async function changeCV(userData) {
  const payload = new FormData();
  payload.append("email", userData.email);
  payload.append("nume", userData.nume);
  payload.append("prenume", userData.prenume);
  payload.append("CV", userData.CV);

  return axios(`${API_URL}/api/user/cv`, {
    method: "put",
    withCredentials: true,
    data: payload
  });
}

export async function downloadCVs() {
  const response = await axios.get(`${API_URL}/api/archive/downloadCVs`, {
    responseType: "blob"
  });

  return response.data;
}

export async function resetPassword(newPassword, resetToken) {
  await axios(`${API_URL}/api/user/reset-password/${resetToken}`, {
    method: "post",
    withCredentials: true,

    data: {
      password: newPassword
    }
  });
}

export async function postTest(testData) {
  await axios(`${API_URL}/api/test/createFull`, {
    method: "post",
    withCredentials: true,

    data: testData
  });
}

export async function getTests() {
  const response = await axios(`${API_URL}/api/test`, {
    method: "get",
    withCredentials: true
  });

  return response.data;
}

export async function testTaken(userId, testId) {
  let isTestTaken = false;

  try {
    const foundTest = await axios(
      `${API_URL}/api/userTest/${userId}/${testId}/taken`,
      {
        method: "get",
        withCredentials: true
      }
    );

    if (foundTest.data === true) isTestTaken = true;
  } catch (err) {
    isTestTaken = false;
  }
  return isTestTaken;
}

export async function postUserTest(userTestData) {
  await axios(
    `${API_URL}/api/userTest/${userTestData.userId}/${userTestData.testId}`,
    {
      method: "post",
      withCredentials: true,
      data: userTestData
    }
  );
}
