import React from 'react'

import styles from "./TestFinished.module.css"

import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from 'primereact/button'

function TestFinished() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const { selectedTest, userID } = state

    function toProfile(){
        navigate("/")
    }

    function toLandingTests(){
        navigate(`/teste-tehnice/${userID}`)
    }

  return (
    <div className={styles.container}>
        <div>
            <h1 className={styles.header}>Ai terminat cu succes testul {selectedTest.nume} de la {selectedTest.partener}</h1>

            <p className={styles.content}>Vom reveni cu vești în curând pe mail!</p>

            <div className={styles.buttonsContainer}>
                <Button label="Profil" onClick={toProfile} />
                <Button label="Teste tehnice" onClick={toLandingTests} />
            </div>
        </div>
    </div>
  )
}

export default TestFinished