import React from 'react'

import styles from "./TestModal.module.css"

function TestModal() {
  return (
    <div className={styles.modal}></div>
  )
}

export default TestModal