import React from 'react'

import styles from "./ViewResults.module.css"

import ResultsTable from '../../../Components/Admin/ResultsTable/ResultsTable'

function ViewResults() {

  return (
          <div>
            <div className={styles.header}>Test Results</div>

            <ResultsTable />
          </div>
  )
}

export default ViewResults