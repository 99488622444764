import React, { useEffect, useState } from 'react'

import styles from "./TestCard.module.css"

import { Card } from 'primereact/card';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { useNavigate, useParams } from 'react-router-dom';
import { testTaken } from '../../utils/apiCalls';

function TestCard({ testData, imageSrc }) {
  const navigate = useNavigate();
  const { userID } = useParams();
  const [isActive, setIsActive] = useState()
  
  useEffect(() => {
    async function isActive() {
      const testActive = await testTaken(userID, testData.id);
      setIsActive(!testActive);
    }

    isActive()
  },
    []
  )

  let tipTest = testData.esteGrila ? "Grilă" : "Open Answer"

  const header = () => {
    return <img src={imageSrc} alt={`${testData.partener}`} className={styles.partnerLogo} />
  }

  const confirmTest = () => {
    confirmDialog({
      header: "Atenție",
      message: `În cadrul testului ${testData.nume} de la ${testData.partener} nu ai voie să folosești surse terțe. Nerespectarea acestei reguli duce la descalificare. Testul poate fi susținut o singură dată! Fiecare întrebare valorează un punct, maximul fiind de 11p. Întrebările 1-10 sunt întrebări tip grilă cu răspuns unic sau multiplu. Nu se acordă punctaj intermediar. Mult succes!`,
      accept: handleNavigation
    })
  }

  function showConfirm() {
    if (isActive) {
      confirmTest()
    }
  }

  function handleNavigation() {
    navigate(`./${testData.id}`, { state: testData })
  }

  return (
    <div>
      <ConfirmDialog />
      <Card onClick={showConfirm} header={header} title={testData.partener} subTitle={testData.nume} className={styles.card}>
        <p className='m-0 font-bold'>Tip test: {tipTest}</p>
        <p className='m-0 font-bold'>Număr întrebări: {testData.nrIntrebari}</p>
        {isActive === false && <span className={styles.testCompleted}>Ai dat deja testul</span>}

        {isActive === false && <div className={styles.modal}></div>}
      </Card>
    </div>
  )
}

export default TestCard