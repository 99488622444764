import React from 'react'

import styles from "./CreateTest.module.css"

import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { parteneri, tipTest } from '../../../const'
import { nrIntrebari as intrebariOptions } from '../../../const'

import { TestContext } from '../../../context/TestProvider';
import { useContext } from 'react';

function CreateTest({ setShowPopup }) {
    const { testData, dispatchTest } = useContext(TestContext)

    const isDisabled = !testData.partner || !testData.noQuestions || !testData.name || testData.multipleChoice === null ? true : false

  return (
    <div className={styles.container}>
    <div className={styles.card}>
        <div>
        <div className={styles.dropdownContainer}>
            <Dropdown value={testData.partner} placeholder="Select partner" filter onChange={(event) => dispatchTest({type: "partner", payload: event.value})} options={parteneri} className={styles.dropdown} />

            <InputText placeholder="Test title" onChange={(event) => dispatchTest({type: "name", payload: event.target.value})} />

            <Dropdown  value={testData.multipleChoice} placeholder="Test type"onChange={(event) => dispatchTest({type: "testType", payload: event.value})} options={tipTest} optionLabel="tip" className={styles.dropdown} />

            <Dropdown value={testData.noQuestions} placeholder="Select no. of questions" onChange={(event) => dispatchTest({type: "noQuestions", payload: event.value})} options={intrebariOptions} className={styles.dropdown} />
        </div>

        <div className={styles.buttonContainer}>
        <Button label="Confirm" disabled={isDisabled} className={styles.button} onClick={() => setShowPopup(true)} />
        </div>
        </div>
    </div>
    </div>
  )
}

export default CreateTest