import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../const";
import styles from "./TestMultipleChoice.module.css"


function QuestionImg({ currentQuestion }) {

    const [status, setStatus] = useState(404);
    useEffect(() => {
        try {
            axios.get(`${API_URL}/api/intrebare/poze/intrebare${currentQuestion}.png`).then((res) => {
                setStatus(res.status);
            }).catch((err) => {
                console.log("ceva")
                console.log(err);
                setStatus(404);
            });
        } catch (err) {
            console.log(err);
        }
    }, [currentQuestion])

    console.log(status);

    if (status == 200) {
        return (
            <img className={styles.imageRes} src={`${API_URL}/api/intrebare/poze/intrebare${currentQuestion}.png`} alt="poza" />
        )
    }
    else if (status == 404) {
        return (
            <></>
        )
    }


}

export default QuestionImg;