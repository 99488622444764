import React from "react";
import PostInterval from "../../../hooks/usePostInterval";
import useAvailableIntervals from "../../../hooks/useAvailableIntervals";
import style from "./DataTable.module.css";
import { Toaster, toast } from "react-hot-toast";


export default function DataTable({ userId, reset }) {
  const data = useAvailableIntervals();

  return data ? (
    <div className={style.content}>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Ora</th>
            <th>Actiune</th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, key) => {
            return (
              <tr key={key}>
                <td>{formatDate(val.data)}</td>
                <td>{val.timp}</td>
                <td>
                  <button
                    onClick={async () => {
                      const res = await PostInterval(userId, val.id);
                      if (res.status == 400) {
                        toast.error(res.message);
                      } else if (res.status == 200) {
                        toast.success("Te-ai înregistrat cu succes!");
                      }
                      reset(true);
                    }}
                  >
                    Rezerva
                  </button>{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
        <Toaster />

      </table>
    </div>
  ) : (
    <div className={style.content}>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Ora</th>
            <th>Actiune</th>
          </tr>
        </thead>
        <tbody>
          <td colSpan={3}> Nu exista date disponibile</td>
        </tbody>
      </table>
    </div>
  );
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
