import React from 'react'
import styles from "./Admin.module.css"

import Table from '../../../Components/Admin/DataTable/Table';

function Admin() {
  return (
          <div className={styles.adminContainer}>
            <div className={styles.adminText}>Welcome, Admin</div>

            <Table />        
          </div>
  )
}

export default Admin
