import React from 'react'

import { FaUser } from "react-icons/fa"
import { FaFileUpload } from "react-icons/fa"
import { FaBinoculars } from "react-icons/fa"
import { FaCoffee } from "react-icons/fa"
import { FaBook } from "react-icons/fa"
import { AiFillSetting } from "react-icons/ai"

import { Link } from 'react-router-dom'
import styles from "./AdminMenu.module.css"

function AdminMenu({ showMenu,setShowMenu }) {
  return (
    <div className={`${styles.menuContainer} ${showMenu ? styles.showMenu : ''}`}>
        <section className={styles.firstSectionContainer}>

            <Link to="/admin" style={{ textDecoration: 'none', color: "black" }}>
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaUser />
                    <p>View Users</p>
                </div>
            </Link>

        </section>

        <section className={styles.sectionContainer}>
            <h2 className={styles.sectionTitle}>Interviews</h2>

            <Link to="" style={{ textDecoration: 'none', color: "black" }}>
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaFileUpload />
                    <p>Load Interviews</p>
                </div>
            </Link>

            <Link to="/admin/view-booked" style={{ textDecoration: 'none', color: "black" }}>
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaBinoculars />
                    <p>View Booked</p>
                </div>
            </Link>

            {/* <Link to='/admin/?view=feedback' style={{ textDecoration: 'none', color: "black" }}> */}
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaCoffee />
                    <Link to={'/admin/?view=feedback'} style={{textDecoration: 'none', color: 'inherit', marginBlockStart: '1em', marginBlockEnd: '1em'}}>View Interview Feedback</Link>
                </div>
            {/* </Link> */}
        </section>

        <section className={styles.sectionContainer}>
            <h2 className={styles.sectionTitle}>Tests</h2>

            <Link to="/admin/load-tests" style={{ textDecoration: 'none', color: "black" }}>
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaFileUpload />
                    <p>Load Tests</p>
                </div>
            </Link>

            <Link to="/admin/view-results" style={{ textDecoration: 'none', color: "black" }}>
                <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                    <FaBook />
                    <p>View Results</p>
                </div>
            </Link>
        </section>

        <section className={styles.sectionContainer}>
            <h2 className={styles.sectionTitle}>Settings</h2>

            <div onClick={() => setShowMenu(false)} className={styles.inlineItem}>
                <AiFillSetting />
                <p>Change Password</p>
            </div>
        </section>
    </div>
  )
}

export default AdminMenu