import React from 'react'

import style from "./LandingTests.module.css"

import TestCard from './TestCard';

import BCR_LOGO from "../../assets/images/bcr_logo.png"
import UBISOFT_LOGO from "../../assets/images/ubisoft_logo.png"
import EY_LOGO from "../../assets/images/ey_logo.png"

import { TestContext } from '../../context/TestProvider';
import { useContext } from 'react';
import { testTaken } from '../../utils/apiCalls';
import { useParams } from 'react-router-dom';


function LandingTests() {
  const { tests } = useContext(TestContext)
  const { userID } = useParams()

  const landingTestsBody = tests.length > 0 ? tests.map((test) => {

    let imageLink = "";

    switch (test.partener) {
      case "BCR":
        imageLink = BCR_LOGO
        break

      case "Ubisoft":
        imageLink = UBISOFT_LOGO
        break

      case "EY":
        imageLink = EY_LOGO
        break
    }

    return <TestCard key={test.id} testData={test} imageSrc={imageLink} />
  }) : <div>Nu exista teste</div>

  return (
    <div className={style.container}>
      {landingTestsBody}
    </div>
  )
}

export default LandingTests