import React, { useReducer } from 'react'

export const UserTestContext = React.createContext()

const initialMultipleChoiceState = {
    testId: "",
    userId: "",
    nrIntrebariCorecte: 0,
    punctajTotal: 0
}

function handleMultipleChoiceTest(state, action){
    let newState = {...state}

    switch(action.type){
        case "loadTest":
            const { testId, userId } = action.payload

            newState['testId'] = testId
            newState['userId'] = userId

            return newState

        case "answer":
            const { punctaj } = action.payload

            newState['punctajTotal'] = state.punctajTotal + punctaj

            if(punctaj > 0){
              newState['nrIntrebariCorecte'] = state.nrIntrebariCorecte + 1
            }

            return newState

        case "reset":
          newState = {...initialMultipleChoiceState}

          return newState
    }
}

const initialOpenTestState = {
  testId: "",
  userId: "",
  raspunsuri: []
}

function handleOpenTest(state, action){
  let newState = {...state}

  switch(action.type){
      case "loadTest":
          const { testId, userId } = action.payload

          newState['testId'] = testId
          newState['userId'] = userId

          return newState

      case "answer":
          const raspuns = action.payload

          let raspunsuri = [...newState['raspunsuri']]
          raspunsuri.push(raspuns)

          newState['raspunsuri'] = raspunsuri

          return newState

      case "reset":
        newState = {...initialOpenTestState}

        return newState
  }
}

function UserTestProvider({ children }) {
    const [userTestData, setUserTestData] = useReducer(handleMultipleChoiceTest, initialMultipleChoiceState) // multiple choice test
    const [userOpenTest, setUserOpenTest] = useReducer(handleOpenTest, initialOpenTestState) // open answer test

    const userTestDataValue = {
      userTestData, 
      dispatchUserTest: setUserTestData,
      userOpenTest,
      dispatchUserOpenTest: setUserOpenTest
    }

  return (
    <UserTestContext.Provider value={userTestDataValue}>{children}</UserTestContext.Provider>
  )
}

export default UserTestProvider