import React, { useState } from 'react'

import styles from "./UserTestContainer.module.css"

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import TestMultipleChoice from '../../Components/TesteTehnice/TestMultipleChoice'
import TestOpenAnswer from '../../Components/TesteTehnice/TestOpenAnswer'
import TestBothQuestionTypes from '../../Components/TesteTehnice/TestBothQuestionTypes'
import UserTestProvider from '../../context/UserTestProvider'

function UserTestContainer() {
    const { state: selectedTest } = useLocation()
    const { userID, testID } = useParams()
    const navigate = useNavigate()

    const [currentQuestion, setCurrentQuestion] = useState(1)

    function nextQuestion(noQuestion) {
        if (noQuestion <= selectedTest.nrIntrebari) {
            setCurrentQuestion(noQuestion)
        } else {
            navigate(`/teste-tehnice/${userID}/${testID}/completat`, { state: { selectedTest: selectedTest, userID: userID } })
        }
    }

    selectedTest.esteGrila = true;

    return (
        <UserTestProvider>
            <div className={styles.testContainer}>
                <h1 className={styles.testHeader}>{selectedTest.nume} Test - {selectedTest.partener}</h1>

                <h2 className={styles.miniHeader}>Intrebarea {currentQuestion}</h2>

                <TestBothQuestionTypes selectedTest={selectedTest} currentQuestion={currentQuestion} nextQuestion={nextQuestion}/>
                {/* {selectedTest.esteGrila ?
                    <TestMultipleChoice selectedTest={selectedTest} currentQuestion={currentQuestion} nextQuestion={nextQuestion} />
                    :
                    <TestOpenAnswer selectedTest={selectedTest} currentQuestion={currentQuestion} nextQuestion={nextQuestion} />} */}
            </div>
        </UserTestProvider>
    )
}

export default UserTestContainer