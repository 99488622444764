import React from 'react'
import style from './Register.module.css'
import {FormularRegister} from '../../Components/FormularRegister/FormularRegister'

function Register() {
  return (
    <div>
      <FormularRegister />
    </div>
  )
}

export default Register
