import React, { useRef, useState } from 'react'

import { Button } from "primereact/button"

import { AiOutlineMenu } from "react-icons/ai"
import {  MdArrowDropDown } from "react-icons/md"
import { GrLogout } from "react-icons/gr"

import styles from "./AdminNavbar.module.css"

import { RootContext } from '../../../context/RootProvider'
import { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { downloadCVs } from '../../../utils/apiCalls'

import  download  from 'downloadjs' 

function AdminNavbar({ showMenu, setShowMenu }) {
    const [showDownloadCVs, setShowDownloadCVs] = useState(false)

    const resumeRef = useRef()

    const rootContext = useContext(RootContext)

    const navigate = useNavigate()

    function handleLogout(){
        rootContext.setUser(null)
        rootContext.setIsUserAdmin(false)
        
        navigate("/")
    }

    function changeMenuVisibility(){
        setShowMenu(!showMenu)
    }

    function toggleDownloadArea(){
        setShowDownloadCVs(!showDownloadCVs)
    }

    async function handleDownloadCVs(){
        const fileBlob = await downloadCVs()
        download(fileBlob, "CVs.zip", "application/zip")
    }

  return (
    <div className={styles.navbarContainer}>
        <div className={styles.container}>
            <AiOutlineMenu onClick={changeMenuVisibility} size={30} cursor="pointer" />
            <p className={styles.content}>Dashboard</p>
        </div>

        <div className={styles.container}>
            <div className={styles.resume}>
                <div onClick={toggleDownloadArea} className={`${styles.container} ${styles.onHover}`}>
                    <p ref={resumeRef}>Resumes</p>
                    <MdArrowDropDown size={24} />
                </div>

                <div className={`${showDownloadCVs === true ? styles.downloadArea : styles.hideDownloadArea}`}>
                    <Button onClick={handleDownloadCVs} label="Download CVs" icon="pi pi-download" iconPos="right" />
                </div>
            </div>

            <div onClick={handleLogout} className={`${styles.container} ${styles.logout} ${styles.onHover}`}>
                <GrLogout size={30} />
                <p>Logout</p>
            </div>
        </div>
    </div>
  )
}

export default AdminNavbar