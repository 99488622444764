import style from "./ForgotPassword.module.css";
import {Button} from "../../Components/Button/Button"
import { useState } from "react";

function ForgotPassword(){
    const [email, setEmail] = useState('');

    const handleSend = () =>{
        console.log(email)
    }

    const handleInputChange = (e) =>{
        setEmail(e.target.value)
    }

    return(
        <div className={style.content}>
            <div className={style.table}>
                Completeaza emailul contului
                <input type="text" value={email} onChange={handleInputChange}/>
                <Button className="btnSignIn">

                    <div className={style.align} onClick={handleSend}>
                        Trimite
                    </div>

                </Button>
            </div>
        </div>
    )
}

export default ForgotPassword