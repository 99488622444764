import React from 'react'

import styles from "./AdminWrapper.module.css"

import AdminNavbar from '../Admin/AdminNavbar/AdminNavbar'
import AdminMenu from '../Admin/AdminMenu/AdminMenu'
import AdminModal from '../Admin/AdminModal/AdminModal'

import { Outlet } from 'react-router-dom'

import { useState } from 'react'

function AdminWrapper() {
    const [showMenu, setShowMenu] = useState(false)

  return (
    <div>
        <AdminNavbar showMenu={showMenu} setShowMenu={setShowMenu} />
        <AdminMenu showMenu={showMenu} setShowMenu={setShowMenu} />

        <div className={styles.adminContainer}>
        <Outlet />
        </div>
        
        <AdminModal showMenu={showMenu} setShowMenu={setShowMenu} />
    </div>
  )
}

export default AdminWrapper