import React from 'react'

import styles from "./ResultsTable.module.css"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dropdown } from 'primereact/dropdown';

import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { testResultsDummy } from '../../../const';
import { rowsToDisplay } from '../../../const';

import { useState } from 'react';
import { useEffect } from 'react';

const columns = [
    { field: "id", header: "Test ID", sortable: false, dataType: "text" },
    { field: "userId", header: "User ID", sortable: false, dataType: "text" },
    { field: "nume", header: "Nume", sortable: false, dataType: "text" },
    { field: "prenume", header: "Prenume", sortable: false, dataType: "text" },
    { field: "punctajTotal", header: "Punctaj", sortable: true, dataType: "numeric" },
    { field: "nrIntrebariCorecte", header: "Numar intrebari corecte", sortable: true, dataType: "numeric" },
]

function ResultsTable() {
    const [testResults, setTestResults] = useState([])
    const [totalTestResults, setTotalTestResults] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [firstRowIndex, setFirstRowIndex] = useState(0)
    const [rows, setRows] = useState(6)

    const [filters, setFilters] = useState(null)
    const [globalFilterValue, setGlobalFilterValue] = useState('')

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
  
        _filters['global'].value = value;
  
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'userId': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'nume': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'prenume': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'punctajTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            'nrIntrebariCorecte': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        })
    }

    const clearFilter = () => {
        initFilters();
    }

    useEffect(() => {
        setTimeout(() => {
            setTestResults(testResultsDummy)
            setTotalTestResults(testResultsDummy.length)
            setIsLoading(false)

            initFilters()
        }, 1000)
    }, [])

    const onPage = (event) => {
        setIsLoading(true)
  
        setTimeout(() => {
          const startIndex = event.first
  
          setFirstRowIndex(startIndex)
          setIsLoading(false)
        }, 1000)
      }

    const dynamicColumns = columns.map(column => {
        return <Column key={column.field} field={column.field} header={column.header} sortable={column.sortable} filter dataType={column.dataType}  />
    })

    const renderHeader = () => {
        return(
            <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter} />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
        )
    }

    const header = renderHeader()

  return (
    <div className={styles.tableContainer}>

        <div className={styles.dropdownContainer}>
        <p>Select the number of rows to display</p>

        <Dropdown optionLabel='rows' 
                  value={rows} 
                  optionValue="rows" 
                  options={rowsToDisplay} 
                  onChange={(event) => {
                                        setRows(event.value)
                                        setFirstRowIndex(0)
                                        }} 
                  placeholder="Rows to display" />
        </div>

        <DataTable 
        value={testResults}
        filters={filters}
        globalFilterFields={['id', 'userId', 'nume', 'prenume', 'punctajTotal', 'nrIntrebariCorecte']}
        header={header}
        paginator
        onPage={onPage} 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tests"
        first={firstRowIndex}
        totalRecords={totalTestResults} 
        responsiveLayout="scroll"
        rows={rows} 
        removableSort
        loading={isLoading}
        emptyMessage="No tests were found"
        showGridlines
        >
            {dynamicColumns}
        </DataTable>
    </div>
  )
}

export default ResultsTable