import React from 'react'

import styles from "./ViewBooked.module.css"

import InterviewTable from '../../../Components/Admin/InterviewTable/InterviewTable'

function ViewBooked() {
  return (
    <div>
            <div className={styles.header}>Interviews booked</div>

            <InterviewTable />
          </div>
  )
}

export default ViewBooked