import React from 'react'

import styles from "./ChangePassword.module.css"

import Logo from "../../assets/images/ttj__large_purple_beige2.png";

import { Button } from "primereact/button";

import 'primeicons/primeicons.css';

import { useState } from 'react';

import { resetPassword } from '../../utils/apiCalls';
import { useNavigate, useParams } from 'react-router-dom';

const initialStateValue = {
  newPass: "",
  confirmPass: "",
}

const initialStateTypes = {
  newPassType: "password",
  confirmPassType: "password"
}

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

function ChangePassword() {
  const [inputs, setInputs] = useState(initialStateValue)
  const [inputsTypes, setInputsTypes] = useState(initialStateTypes)

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const navigate = useNavigate()
  let { resetToken } = useParams()

  function handleOnChangeInput(inputName, value){
    setInputs((prevInputs) => {
      return {...prevInputs, [inputName]: value}
    })
  }

  function handleInputType(inputTypeName){
    if(inputsTypes[inputTypeName] === "password"){
      setInputsTypes((prevInputsTypes) => {
        return {...prevInputsTypes, [inputTypeName]: "text"}
      })
    }else{
      setInputsTypes((prevInputsTypes) => {
        return {...prevInputsTypes, [inputTypeName]: "password"}
      })
    }
  }

  async function handleSubmit(){
    const { newPass, confirmPass } = inputs

    if(!newPass.match(passwordRegex) || !confirmPass.match(passwordRegex)){
      setError(true)
      setErrorMessage("Password must contain at least 8 characters, one letter and one number")
    }else if(newPass !== confirmPass){
      setError(true)
      setErrorMessage("Passwords don't match")
    }else{
      setError(false)
      setErrorMessage("")

      await resetPassword(newPass, resetToken)

      navigate("/")
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <img src={Logo} alt="ttj-logo" className={styles.logo} />

        <div>
          <div className={styles.inputContainer}>
          <label htmlFor="new-pass" className={styles.newPass}>Noua parola</label>
          <input onChange={(event) => handleOnChangeInput("newPass", event.target.value)} type={inputsTypes.newPassType} id="new-pass" className={styles.input} />
          <i onClick={() => handleInputType("newPassType")} className={`pi ${inputs.newPass === "" ? styles.hideIcon : ""} ${ inputsTypes.newPassType === "password" ? "pi-eye" : "pi-eye-slash"} ${styles.icons}`} />
          </div>

          <div className={styles.inputContainer}>
          <label htmlFor="confirm-pass" className={styles.confirmPass}>Confirma parola</label>
          <input onChange={(event) => handleOnChangeInput("confirmPass", event.target.value)} type={inputsTypes.confirmPassType} id="confirm-pass" className={styles.input} />
          <i onClick={() => handleInputType("confirmPassType")} className={`pi ${inputs.confirmPass === "" ? styles.hideIcon : ""} ${ inputsTypes.confirmPassType === "password" ? "pi-eye" : "pi-eye-slash"} ${styles.icons}`} />
          </div>

          <div className={styles.passwordError}>{errorMessage}</div>

          <div onClick={handleSubmit} className={styles.buttonContainer}>
          <Button label="Confirm" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword